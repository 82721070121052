import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';


const MenuItems = ({menu, show, ...props}) => {

    

    return (
        <div className='menuBody'>
            {menu.map((eachItem)=>{
                return(
                    eachItem.isActive &&
                    <div className='menuSection' key={eachItem.id}>
                        <div className='menuImage'> <img src={eachItem.img} alt='biryani img' width='300' height='220px'/> </div>
                        <div className='itemName'> {eachItem.itemName} </div>
                        <div className='menuContent'> {eachItem.Desc} </div>
                        <div className='prices'> Half tray: {eachItem.half}, Full tray: {eachItem.full}</div>
                        <div className='addInfo'> {eachItem.addInfo} </div>
                        <div className='menuAction'> <Button variant="danger" size="lg" onClick={props.handleShow}> How to Order? </Button></div>
                        <Modal show={show} onHide={props.handleClose}>
                            <Modal.Header closeButton>
                            <Modal.Title><span style={{ backgroundColor: 'yellow'}}>How to Order Barkas Biryani?</span></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Startup with a mission. "Taste the difference quality makes"</Modal.Body>
                            <Modal.Body>Call/Whatsapp '859-338-zero nine three five' Or email us at <a href='mailto: barkasbiryani@gmail.com'>barkasbiryani@gmail.com</a></Modal.Body>
                            <Modal.Body>Click 'Order Now' button and talk to one of associates on facebook messenger to place an order. </Modal.Body> 
                            <Modal.Footer className='modalFooter'>
                            <div>
                                <a href='https://www.facebook.com/BarkasBiryaniDFW/'>
                                    <Button variant="danger" onClick={props.handleClose}>
                                        Order Now
                                    </Button>
                                </a>
                            </div>
                            <div style={{fontSize: 'small'}}>
                                <div>***All orders must be placed one day in advance.</div>
                                Note: No msg | Halal meat only | Currently serving Northlake, Argyle, Lantana and Southlake areas only.
                            </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            })}
        </div>
    )
}

export default MenuItems;