import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Menu from './Menu';
import Home from './Home';
import SelectDateForMenu from './components/SelectDateForMenu';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='/menu'>
          <Menu />
        </Route>
        <Route path='/datepicker'>
          <SelectDateForMenu />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
