import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const SelectDateForMenu = () => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const deliveryWindow = [{
      session: 'Lunch',
      time: '11:30PM - 1:00PM'
    },
    {
      session: 'Dinner',
      time: '6:00PM - 7:00PM'
    }]
    const [dayChoosen, setDayChoosen] = useState();
    const [showDeliveryWindow, setShowDeliveryWindow] = useState(false);
    const [dayAndSessionChosen, setDayAndSessionChosen] = useState();

    const history = useHistory();

    const dayButtonClickHandler= (day) => {
      setDayChoosen(day);
      setShowDeliveryWindow(true);
    }

    const deliverySessionHandler = (deliverySession) => {
      const tempDayandSessionChosen = {
        dayChosen: dayChoosen,
        sessionChosen: deliverySession
      }
      history.push({
        pathname: "/menu",
        state: tempDayandSessionChosen
      });
      setDayAndSessionChosen(tempDayandSessionChosen);
    }

    return(
        <div className="MenuContainer">
        {!showDeliveryWindow && <React.Fragment>
          <div className="DatapickerHeader">
              Please Choose a Day For Menu
          </div>
          <div className="body Datepickerrow">
            {days.map((eachDay)=>{
              return <div>
                    <Button
                      variant="success"
                      size="lg"
                      className="DateMenuButton"
                      onClick={()=>dayButtonClickHandler(eachDay)}
                    > 
                      <strong>{eachDay}</strong>
                    </Button>
                  </div>
            })}
          </div>
        </React.Fragment>}
        {showDeliveryWindow && <React.Fragment>
          <div className="DatapickerHeader">
              Please Choose a Delivery Window for <span style={{color: 'orange', fontSize: 'larger'}}><u>{dayChoosen}</u></span>
          </div>
          <div className="body Datepickerrow">
          {console.log(dayChoosen === 'Sunday' || dayChoosen === 'Saturday')}
            {(dayChoosen === 'Sunday' || dayChoosen === 'Saturday') ? deliveryWindow.map((eachDeliveyWindow)=>{
              return <div><Button
                  variant="success"
                  size="lg"
                  className="DateMenuButton"
                  onClick={()=>deliverySessionHandler(eachDeliveyWindow)}
                > 
                <strong>{eachDeliveyWindow.session}</strong>
                </Button></div>
            }) :
            (dayChoosen !== 'Sunday' || dayChoosen !== 'Saturday') && <div><Button
                  variant="success"
                  size="lg"
                  className="DateMenuButton"
                  onClick={()=>deliverySessionHandler(deliveryWindow[1])}
                > 
                <strong>Dinner</strong>
                </Button></div>
            }
          </div>
        </React.Fragment>}
          
        </div>
    )
}

export default SelectDateForMenu;