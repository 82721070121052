import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import MenuItems from './components/MenuItems';
import { Link } from 'react-router-dom';
import { data } from './MenuData';
import logo from './logo.png';
import { Button } from 'react-bootstrap';

const Menu = () => {

    const [intitialData] = useState(data);
    const [menu, setMenu] = useState(data);
    const [show, setShow] = useState(false);
    const [showOnlyVeg, setShowOnlyVeg] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const locationData = useLocation();
    const [chosenDate, setChosenDate] = useState();

    useEffect(()=>{
        if(locationData.state){
            console.log("menu item location data", locationData);
            window.localStorage.setItem('Delivery-Day-Time', JSON.stringify(locationData.state));
        }
        setChosenDate(JSON.parse(window.localStorage.getItem('Delivery-Day-Time')));
    }, [locationData.state]);

    useEffect(()=>{
        return ()=>{
            localStorage.removeItem("Delivery-Day-Time")
        }
    }, []);

    const handleToggle = () => {
        const menuData = [...menu];
        if(!showOnlyVeg){
            var newMenu = menuData.filter((eachItem)=>eachItem.isVeg === !showOnlyVeg);
            setMenu(newMenu);
        }else{
            setMenu(intitialData);
        }
        setShowOnlyVeg(!showOnlyVeg);
    }
    return(
        <div>
            <div className='menuItem'>
                <Link to='/'><img src={logo} alt="Logo" width='220px' height='80px'/></Link>
            </div>
            <div className="MenuButtonSection">
                <div className='toggleButton'>
                    <Button class="btn btn-secondary active" onClick={handleToggle}>
                        {!showOnlyVeg ? 'Show Veg Menu Only' : 'Show All Items'}
                    </Button>
                </div>
                <div className='menuItem'>
                    Seeing Menu for <span style={{color: 'orange', fontSize: 'larger'}}>{chosenDate && chosenDate.dayChosen && chosenDate.dayChosen}</span>
                    (Delivery: {chosenDate && chosenDate.sessionChosen.session}, {chosenDate && chosenDate.sessionChosen.time})
                </div>
            </div>
            
            <MenuItems menu={menu} show={show} handleClose={handleClose} handleShow={handleShow}/>
        </div>
    )
}

export default Menu;
