export const data = [
    {
        "id": "1",
        "itemName": "Chicken Mughlai Biryani",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/kakachicken.png",
        "addInfo": "***comes with Raita",
        "Desc": "Biryani cooked with the meat, rice and spices that are layered in a handi. The mouth of the handi is sealed such that it leads to building of pressure in the pot which not only aids in cooking but also helps the Biryani retain the flavour.",
        "half": "$70",
        "full": "130",
        "single": "$12",
        "isVeg": false,
        "isActive": true
    },
    {
        "id": "2",
        "itemName": "Barkas Mutton Biryani",
        "addInfo": "***comes with Raita",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/kakamutton.png",
        "Desc": "The kind of Biryani that is popular in South India, esp. you get to eat in muslim weddings. Cooked with loads of onions, spices and curd. Mutton is well cooked until it becomes tender.",
        "half": "$85",
        "full": "$165",
        "single": "$14",
        "isVeg": false,
        "isActive": true
    },
    {
        "id": "3",
        "itemName": "Cashew Biryani",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/cashewbiryani.jpeg",
        "addInfo": "***comes with Raita",
        "Desc": "Biryani cooked with the cashew, rice and spices that are layered in a handi. The mouth of the handi is sealed such that it leads to building of pressure in the pot which not only aids in cooking but also helps the Biryani retain the flavour.",
        "half": "$60",
        "full": "110",
        "single": "$11",
        "isVeg": true,
        "isActive": true
    },
    {
        "id": "4",
        "itemName": "Barkas Gobi Dynamite",
        "addInfo": "***comes with onion salad",
        "half": "$60",
        "full": "$110",
        "single": "$9",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/gobi777.png",
        "Desc": "Cauliflower marinated in corn flour batter, deep fried and tossed with spices and house made sauce. ",
        "isVeg": true,
        "isActive": true
    },
    {
        "id": "5",
        "itemName": "Barkas Chicken Curry",
        "addInfo": "**Basmati Rice and Curry packed separately",
        "half": "$70",
        "full": "$130",
        "single": "$12",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/chickenCurry.png",
        "Desc": "Boneless chicken thigh cubes well marinated and cooked with flavors. thanks to all spices. hearty when you finish it off with basmati rice",
        "isVeg": false,
        "isActive": true
    },
    {
        "id": "6",
        "itemName": "Paneer Butter Masala",
        "addInfo": "***Basmati Rice and Curry packed separately",
        "half": "$60",
        "full": "$110",
        "single": "$11",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/paneerbuttermasala.png",
        "Desc": "Paneer cubes cooked with mildly sweet creamy gravy made with butter, tomatoes, cashews, and spices made to perfection. NO COLOR ADDED",
        "isVeg": true,
        "isActive": true
    },
    {
        "id": "7",
        "itemName": "Palak Paneer",
        "addInfo": "***Basmati Rice and Curry packed separately",
        "half": "$60",
        "full": "$110",
        "single": "$11",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/palakpaneer.png",
        "Desc": "Paneer cubes cooked with fresh spinach, onions, spices, paneer and herbs",
        "isVeg": true,
        "isActive": true
    },
    {
        "id": "8",
        "itemName": "Barkas Chicken 777",
        "half": "$75",
        "full": "$140",
        "single": "$9",
        "addInfo": "***comes with onion salad",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/chicken777.png",
        "Desc": "Chicken lightly battered, fried to create light, crispy coating and then tossed in herbs and spices.",
        "isVeg": false,
        "isActive": true
    },
    {
        "id": "9",
        "itemName": "Mango Lassi",
        "half": "$50",
        "full": "$90",
        "single": "$5",
        "addInfo": "",
        "img": "https://barkasbiryaniimages.s3.amazonaws.com/mangoLassi.jpeg",
        "Desc": "Yoghurt based Indian mango smoothie/milkshake to cool the effect of hot summers.",
        "isVeg": true,
        "isActive": true
    }
];