import React, { useState } from 'react';
import './App.css';
import logo from './logo.png';
import { Button, Badge, Modal} from 'react-bootstrap';
import { FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Home = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <div className='video-container'>
            <div className="app">
                {/**<video autoplay='autoplay' muted loop='loop'>
                    <source src={barkasvideo} type="video/mp4" />
                    </video> **/}
                <div className="caption">
                    <div className='menu'>
                        <div className='menuItem'>
                            <img src={logo} alt="Logo" width='300px' height='100px'/>
                        </div>
                    </div>
                    <div className='body'>
                        <div>
                            <h1> It's not <Badge bg="secondary">JUST</Badge> Biryani </h1>
                        </div>
                        <div style={{paddingTop: '20px'}}>
                            <h1> From the makers of &nbsp;
                            <a href='https://www.facebook.com/kakabiryanis/reviews' target='new'>
                            <Button size="lg" variant='light' onClick="location.href='http://www.example.com'">Kaka Biryani at Dallas</Button>
                            </a> </h1>
                        </div>
                        <div style={{textDecoration: '', paddingTop: '20px'}}>
                            <h1> 'Our mission is to deliver fresh, delicious and authentic biryanis straight to your door'</h1>
                        </div>
                        <div style={{paddingTop: '20px'}}>
                            <div style={{paddingTop: '20px'}}>
                            <Link to='/datepicker'> <Button variant="danger" size="lg"> <strong>What's Cooking? See Menu</strong></Button> </Link>
                            <Button variant="warning" onClick={handleShow} size='lg'>
                                Why Barkas Biryani?
                            </Button>
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <a href='https://www.facebook.com/BarkasBiryaniDFW'> <button> <FaFacebook />  </button> </a>
                    </div>
                </div>
                
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title><span style={{ backgroundColor: 'yellow'}}>Why Barkas Biryani?</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>Startup on a mission "Taste the difference quality makes"</Modal.Body>
                <Modal.Body>Freshly Handpicked and curated ingredients (No MSG)</Modal.Body>
                <Modal.Body>We only use Halal meat </Modal.Body>
                <Modal.Body>Customer satisfaction is our highest priority</Modal.Body>
                <Modal.Body style={{color: 'green', fontWeight: 'bold'}}>**Currently serving Northlake, Argyle, Lantana and Southlake areas only</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Home
